import gql from 'graphql-tag'

/**
 * Mutation to create a presigned url for the asset
 */
export const CREATE_PRESIGNED_URL = gql`
  mutation createPresignedUrl($assets: AssetsInput!, $prefixKey: String!) {
    createPresignedUrl(assets: $assets, prefixKey: $prefixKey) {
      url
      fileName
      bucketName
    }
  }
`
