













import { ImageSizeEnum } from '@/types'
import Vue from 'vue'

export default Vue.extend({
  name: 'ItemImage',
  props: {
    size: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    itemImageUrl(): string {
      return `${process.env.VUE_APP_PREVIEW_FETCH_URL}/fetch/image/w_200,h_200,f_cover/${this.imageUrl}`
    },
    imageSize(): string {
      switch (this.size) {
        case ImageSizeEnum.Small:
          return '16'
        case ImageSizeEnum.Medium:
          return '32'
        case ImageSizeEnum.Large:
          return '48'
        case ImageSizeEnum.XLarge:
          return '64'
        case ImageSizeEnum.XXLarge:
          return '128'
        default:
          return '48'
      }
    },
  },
})
