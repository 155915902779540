import { CREATE_PRESIGNED_URL } from '@/graphql/mutation/asset'
import apolloClient from '@/services/apollo'
import {
  Assets,
  AssetsInput,
  MutationCreatePresignedUrlArgs,
} from '../../../shared/types/types'

interface SetCreatePresignedUrlResult {
  createPresignedUrl: Assets
}

/**
 * Create presigned url for the asset
 *
 * @param assets object containing fileName and contentType
 * @param prefixKey the folder name in the S3 bucket
 */
export const createPresignedUrl = async (
  assets: AssetsInput,
  prefixKey: string
): Promise<Assets> => {
  const { data } = await apolloClient.mutate<
    SetCreatePresignedUrlResult,
    MutationCreatePresignedUrlArgs
  >({
    mutation: CREATE_PRESIGNED_URL,
    variables: {
      assets,
      prefixKey,
    },
  })

  return data?.createPresignedUrl as Assets
}

/**
 * Upload file to S3
 *
 * @param signedUrl S3 signed url
 * @param file file object to be uploaded
 */
export const uploadFile = async (signedUrl: string, file: File) => {
  return await fetch(signedUrl, {
    method: 'PUT',
    headers: {
      'content-type': 'application/octet-stream',
    },
    body: file,
  })
}

/**
 * Upload file to S3 by generating a presigned url
 *
 * @param file file to be uploaded
 * @param prefixKey for the file to be uploaded
 */
export const uploadFileToS3 = async (
  file: File,
  prefixKey: string
): Promise<string> => {
  const assetInput = {
    fileName: file.name,
    contentType: file.type,
  }
  // Get the presigned URL
  const result = await createPresignedUrl(assetInput, prefixKey)
  const fileToUpload = new File([file], result.fileName, {
    type: file.type,
    lastModified: file.lastModified,
  })
  // upload file with the presigned URL
  const response = await uploadFile(result.url, fileToUpload)
  const parsedUrl = new URL(response.url)
  return parsedUrl.origin + parsedUrl.pathname
}
