import {
  LineItem,
  FulfillmentStatusEnum,
  Price,
  Tracker,
  VariantInput,
} from '../../shared/types/types'

export interface LineItemExtended extends LineItem {
  selectedQuantity?: number
  selectedPrice?: Price
}

export interface FulfillmentCard {
  lineItems: LineItemExtended[]
  orderId: string
  customerName: string
  fulfillmentStatus: FulfillmentStatusEnum
}

export interface LineItemGroup {
  fulfillmentName?: string
  fulfillmentId?: string
  lineItems: LineItemExtended[]
  tracker?: Tracker
  isCanceling?: boolean
}

export enum ImageSizeEnum {
  Small = 'is-small',
  Medium = 'is-medium',
  Large = 'is-large',
  XLarge = 'is-xlarge',
  XXLarge = 'is-xxlarge',
}

export interface VariantOptionLabelMapper {
  id?: string
  index: number
  optionLabel: string
  optionValues: Array<string>
  optionValue?: string
}

export interface PayloadMapper {
  event: DragEvent
  row: VariantOptionLabelMapper
  index: number
}

export interface OptionsMapper {
  key: string
  value: string
}
export interface AuditProps {
  isDeleted?: boolean
  isActive?: boolean
  index?: number
}

export interface VariantInputWithAudit extends AuditProps {
  variant: VariantInput
}

export interface ErrorInfo {
  errors: Array<string>
}

export interface ShippingCarrier {
  name: string
}

/**
 * Generic Types
 */

export enum MessageTypeEnum {
  Error = 'is-danger',
  Info = 'is-info',
  Success = 'is-success',
}

export type MessageInfo = {
  message: string
  messageList: Array<string>
  type: MessageTypeEnum
}
export interface ErrorObject {
  isValid: boolean
  messageList: Array<string>
}

export interface PriceList {
  id: string
  data: string
  isNegative: boolean
  price: Price
}

export interface ProductVariantKey {
  productId: string
  variantId: string
}

export interface Country {
  productId: string
  variantId: string
}
